import React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import styled from 'styled-components';
import { Box } from 'grommet';
import { ResponsiveContext } from 'grommet/contexts';
import Layout from '../layout/primary';
import htmlSerializer from '../prismic/htmlSerializer';

const StyledBox = styled(Box)`
  margin: auto;
  width: 100%;
  max-width: 1440px;
  padding: 20px;
  overflow: hidden;
  @media screen and (min-width: 769px) {
    padding: 90px;
  }
`;

export const query = graphql`
  {
    prismic {
      legal(lang: "en-us", uid: "terms") {
        main_text
        meta_title
        meta_description
      }
    }
  }
`;

export default function({ data }) {
  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const content = data.prismic.legal;

        return (
          <Layout title={content.meta_title} description={content.meta_description}>
            <StyledBox size={size}>
              <RichText render={content.main_text} htmlSerializer={htmlSerializer} />
            </StyledBox>
          </Layout>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
